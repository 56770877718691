var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as d3 from 'd3';
import './Heatmap.scss';
import Utils from "../../Utils";
import Legend from '../Legend';
import HeatmapCanvas from '../HeatmapCanvas';
import EllipsisMenu from '../EllipsisMenu';
import { TemporalXAxisComponent } from '../../Interfaces/TemporalXAxisComponent';
var Heatmap = /** @class */ (function (_super) {
    __extends(Heatmap, _super);
    function Heatmap(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.lineHeight = 12;
        _this.splitByLabelWidth = 140;
        _this.timeLabels = null;
        _this.timeLabelsHeight = 52;
        _this.visibleAggs = null;
        _this.mouseover = function (hoveredAggKey, hoveredSplitBy) {
            var heatmapCanvas = _this.heatmapCanvasMap[hoveredAggKey];
            if (heatmapCanvas)
                heatmapCanvas.render(_this.chartComponentData, _this.chartOptions, hoveredAggKey, hoveredSplitBy, null, null, null, _this.visibleAggs.length === 1);
        };
        _this.mouseout = function (selection, hoveredAggKey) {
            var heatmapCanvas = _this.heatmapCanvasMap[hoveredAggKey];
            if (heatmapCanvas)
                heatmapCanvas.render(_this.chartComponentData, _this.chartOptions, hoveredAggKey, null, null, null, null, _this.visibleAggs.length === 1);
        };
        _this.renderTimeLabels = function (focusStartTime, focusEndTime, focusX1, focusX2, focusY, yOffset, shiftMillis) {
            _this.timeLabels.selectAll(".tsi-heatmapTimeLabels").remove();
            _this.timeLabels.node().parentNode.appendChild(_this.timeLabels.node());
            _this.timeLabels.append("line").attr("class", "tsi-heatmapFocusLine tsi-heatmapTimeLabels")
                .attr("x1", focusX1)
                .attr("x2", focusX1)
                .attr("y1", focusY + yOffset)
                .attr("y2", _this.chartHeight - _this.timeLabelsHeight);
            _this.timeLabels.append("line").attr("class", "tsi-heatmapFocusLine tsi-heatmapTimeLabels")
                .attr("x1", focusX2)
                .attr("x2", focusX2)
                .attr("y1", focusY + yOffset)
                .attr("y2", _this.chartHeight - _this.timeLabelsHeight);
            var textBoxG = _this.timeLabels.append("g")
                .attr("class", "tsi-heatmapTimeLabelTextBox tsi-heatmapTimeLabels");
            var text = textBoxG.append("text");
            text.append("tspan").text(Utils.timeFormat(_this.chartComponentData.usesSeconds, _this.chartComponentData.usesMillis, _this.chartOptions.offset, _this.chartOptions.is24HourTime, shiftMillis, null, _this.chartOptions.dateLocale)(focusStartTime))
                .attr("x", 0)
                .attr("y", 16);
            text.append("tspan").text(Utils.timeFormat(_this.chartComponentData.usesSeconds, _this.chartComponentData.usesMillis, _this.chartOptions.offset, _this.chartOptions.is24HourTime, shiftMillis, null, _this.chartOptions.dateLocale)(focusEndTime))
                .attr("x", 0)
                .attr("y", 30);
            var textDimensions = text.node().getBoundingClientRect();
            textBoxG.append("rect")
                .attr("x", -(textDimensions.width / 2) - 5)
                .attr("y", 0)
                .attr("height", textDimensions.height + 12)
                .attr("width", textDimensions.width + 10);
            text.node().parentNode.appendChild(text.node());
            var rawOffset = (focusX1 + focusX2) / 2;
            var leftOffset = Math.floor(((rawOffset - ((textDimensions.width / 2) + 6)) > 0) ? rawOffset : ((textDimensions.width / 2) + 6));
            textBoxG.attr("transform", "translate(" + leftOffset + "," + (_this.chartHeight - _this.timeLabelsHeight - _this.chartMargins.bottom) + ")");
        };
        _this.chartMargins = {
            top: 0,
            bottom: 8,
            left: 40,
            right: 20
        };
        return _this;
    }
    Heatmap.prototype.focusOnEllipsis = function () {
        if (this.ellipsisContainer !== null) {
            this.ellipsisContainer.select(".tsi-ellipsisButton").node().focus();
        }
    };
    Heatmap.prototype.createControlsPanel = function () {
        this.chartControlsPanel = Utils.createControlPanel(this.renderTarget, this.CONTROLSWIDTH, 52, this.chartOptions);
        this.ellipsisContainer = this.chartControlsPanel.append("div")
            .attr("class", "tsi-ellipsisContainerDiv");
        this.ellipsisMenu = new EllipsisMenu(this.ellipsisContainer.node());
    };
    Heatmap.prototype.chartControlsExist = function () {
        return (this.ellipsisItemsExist() && !this.chartOptions.hideChartControlPanel);
    };
    Heatmap.prototype.addTimeLabels = function () {
        if (this.timeLabels === null || this.svgSelection === null) {
            this.svgSelection = this.heatmapWrapper.append('svg')
                .attr('class', 'tsi-heatmapSVG')
                .attr('title', this.getString('Heatmap'));
            this.timeLabels = this.svgSelection.append('g').attr("class", "tsi-heatmapTimeLabels")
                .attr('transform', 'translate(' + this.chartMargins.left + ',0)');
        }
        if (!this.chartOptions.xAxisHidden) {
            this.xAxis = this.timeLabels.selectAll(".xAxis").data([this.x]);
            this.drawXAxis(this.chartHeight - 60);
            this.xAxis.exit().remove();
            var xAxisBaseline = this.timeLabels.selectAll(".xAxisBaseline").data([this.x]);
            var xAxisBaselineEntered = xAxisBaseline.enter().append("line")
                .attr("class", "xAxisBaseline")
                .attr("x1", .5)
                .merge(xAxisBaseline)
                .attr("y2", this.chartHeight - (this.chartMargins.bottom + this.timeLabelsHeight))
                .attr("y1", this.chartHeight - (this.chartMargins.bottom + this.timeLabelsHeight))
                .attr("x2", this.chartWidth - 90);
            xAxisBaseline.exit().remove();
        }
        if (this.timeLabels.selectAll(".xAxis").size() !== 0) {
            this.timeLabels.selectAll(".xAxis").style("visibility", ((!this.chartOptions.xAxisHidden) ? "visible" : "hidden"));
        }
    };
    Heatmap.prototype.render = function (data, chartOptions, aggregateExpressionOptions) {
        var _this = this;
        _super.prototype.render.call(this, data, chartOptions, aggregateExpressionOptions);
        // override visibleSplitByCap
        this.aggregateExpressionOptions = this.aggregateExpressionOptions.map(function (aE) {
            return __assign(__assign({}, aE), { visibleSplitByCap: 10000 });
        });
        this.chartOptions.setOptions(chartOptions);
        var targetElement = d3.select(this.renderTarget).classed("tsi-heatmapComponent", true);
        if (targetElement.style("position") == "static")
            targetElement.style("position", "relative");
        this.chartComponentData.mergeDataToDisplayStateAndTimeArrays(this.data, this.aggregateExpressionOptions);
        if (this.chartControlsExist() && this.chartControlsPanel === null) {
            this.createControlsPanel();
        }
        else if ((this.chartOptions.hideChartControlPanel || !this.ellipsisItemsExist()) && this.chartControlsPanel !== null) {
            this.chartControlsPanel.remove();
            this.chartControlsPanel = null;
        }
        if (this.chartControlsExist()) {
            this.chartControlsPanel.style("top", (16 + (this.chartOptions.legend === 'compact' ? 32 : 0)) + 'px');
            this.drawEllipsisMenu();
        }
        if (this.heatmapWrapper == null) {
            this.heatmapWrapper = targetElement.append('div')
                .attr("class", "tsi-heatmapWrapper");
            this.draw = function (isFromResize) {
                if (isFromResize === void 0) { isFromResize = false; }
                _this.height = Math.floor(Math.max(d3.select(_this.renderTarget).node().clientHeight, _this.MINHEIGHT));
                _this.chartHeight = _this.height - ((12 + (_this.chartControlsExist() ? 28 : 0) + (_this.chartOptions.legend === 'compact' ? 48 : 0)));
                _super.prototype.themify.call(_this, targetElement, _this.chartOptions.theme);
                _this.width = _this.getWidth();
                if (!isFromResize) {
                    _this.chartWidth = _this.getChartWidth();
                }
                _this.x = d3.scaleTime()
                    .rangeRound([0, _this.chartWidth - 90]); // HARDCODED to be the width of a heatmapCanvas
                var fromAndTo = _this.chartComponentData.setAllValuesAndVisibleTAs();
                _this.x.domain(fromAndTo);
                _this.heatmapWrapper.style("width", _this.chartWidth + (_this.chartMargins.left + _this.chartMargins.right) + "px")
                    .style("height", _this.chartHeight + "px")
                    .style("top", (20 + (_this.chartControlsExist() ? 36 : 0) + (_this.chartOptions.legend === 'compact' ? 40 : 0)) + "px");
                if (_this.chartControlsExist()) {
                    _this.setControlsPanelWidth();
                }
                var canvasWrapperHeightTotal = _this.chartHeight - _this.timeLabelsHeight - _this.chartMargins.bottom;
                _this.heatmapCanvasMap = {};
                _this.visibleAggs = Object.keys(_this.chartComponentData.displayState).filter(function (aggKey) {
                    return _this.chartComponentData.getAggVisible(aggKey);
                });
                var self = _this;
                var canvasWrappers = _this.heatmapWrapper.selectAll(".tsi-heatmapCanvasWrapper")
                    .data(_this.visibleAggs);
                var canvasesEntered = canvasWrappers.enter()
                    .append("div")
                    .merge(canvasWrappers)
                    .attr("class", "tsi-heatmapCanvasWrapper")
                    .style("width", _this.chartWidth + 'px')
                    .style('left', _this.chartMargins.left + 'px')
                    .style("height", function (d, i) {
                    return (canvasWrapperHeightTotal * (1 / _this.visibleAggs.length)) + "px";
                })
                    .style("top", function (d, i) {
                    return ((canvasWrapperHeightTotal * (1 / _this.visibleAggs.length)) * i) + "px";
                }).each(function (aggKey, aggI) {
                    var heatmapCanvas = new HeatmapCanvas(this);
                    self.heatmapCanvasMap[aggKey] = heatmapCanvas;
                    var renderHeatmapCanvas = function () {
                        function onCellFocus(focusStartTime, focusEndTime, focusX1, focusX2, focusY, splitBy) {
                            var shiftMillis = self.chartComponentData.getTemporalShiftMillis(aggKey);
                            self.renderTimeLabels(focusStartTime, focusEndTime, focusX1, focusX2, focusY, (aggI * canvasWrapperHeightTotal / self.visibleAggs.length), shiftMillis);
                            self.legendObject.triggerSplitByFocus(aggKey, splitBy);
                            self.chartOptions.onMouseover(aggKey, splitBy);
                        }
                        heatmapCanvas.render(self.chartComponentData, self.chartOptions, aggKey, null, null, onCellFocus, aggI, self.visibleAggs.length === 1);
                    };
                    renderHeatmapCanvas();
                }).on("mouseleave", function () {
                    self.timeLabels.selectAll(".tsi-heatmapTimeLabels").remove();
                    self.legendObject.legendElement.selectAll('.tsi-splitByLabel').classed("inFocus", false);
                    self.chartOptions.onMouseout();
                });
                canvasWrappers.exit().remove();
                _this.legendObject.draw(_this.chartOptions.legend, _this.chartComponentData, _this.mouseover, _this.heatmapWrapper, _this.chartOptions, _this.mouseout);
                //remove all the colorKeys
                _this.legendObject.legendElement.selectAll(".seriesLabel").selectAll(".tsi-splitByLabel").selectAll(".colorKey").style("display", "none");
                if (isFromResize) {
                    _this.addTimeLabels();
                }
            };
            this.legendObject = new Legend(this.draw, this.renderTarget, this.CONTROLSWIDTH);
        }
        this.chartComponentData.mergeDataToDisplayStateAndTimeArrays(this.data, this.aggregateExpressionOptions);
        this.draw();
        this.gatedShowGrid();
        this.addTimeLabels();
        window.addEventListener("resize", function () {
            if (!_this.chartOptions.suppressResizeListener) {
                _this.draw();
                _this.addTimeLabels();
            }
        });
        this.legendPostRenderProcess(this.chartOptions.legend, this.heatmapWrapper, true);
    };
    return Heatmap;
}(TemporalXAxisComponent));
export default Heatmap;
