var transformTsqResultsForVisualization = function (tsqResults, options) {
    var result = [];
    tsqResults.forEach(function (tsqr, i) {
        var transformedAggregate = {};
        var aggregatesObject = {};
        transformedAggregate[options[i].alias] = { '': aggregatesObject };
        if (tsqr.hasOwnProperty('__tsiError__'))
            transformedAggregate[''] = {};
        else {
            tsqr.timestamps.forEach(function (ts, j) {
                aggregatesObject[ts] = tsqr.properties.reduce(function (p, c) {
                    p[c.name] = aggregatesObject[ts] && aggregatesObject[ts][c.name] !== null ? aggregatesObject[ts][c.name] : c['values'][j];
                    return p;
                }, {});
            });
        }
        result.push(transformedAggregate);
    });
    return result;
};
export { transformTsqResultsForVisualization };
