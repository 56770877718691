import { InstancesSort, HierarchiesExpand, HierarchiesSort } from "./Enums";
export var DefaultHierarchyNavigationOptions = {
    instancesPageSize: 10,
    hierarchiesPageSize: 10,
    isInstancesRecursive: false,
    isInstancesHighlighted: false,
    instancesSort: InstancesSort.DisplayName,
    hierarchiesExpand: HierarchiesExpand.OneLevel,
    hierarchiesSort: HierarchiesSort.Name
};
export var DefaultHierarchyContextMenuOptions = {
    isSelectionEnabled: false,
    isFilterEnabled: false
};
export var nullTsidDisplayString = "null";
export var swimlaneLabelConstants = {
    leftMarginOffset: 40,
    swimLaneLabelHeightPadding: 8,
    labelLeftPadding: 28
};
export var CharactersToEscapeForExactSearchInstance = ['"', '`', '\'', '!', '(', ')', '^', '[', '{', ':', ']', '}', '~', '/', '\\', '@', '#', '$', '%', '&', '*', ';', '=', '.', '_', '-', '<', '>', ',', '?'];
export var NONNUMERICTOPMARGIN = 8;
export var LINECHARTTOPPADDING = 16;
export var GRIDCONTAINERCLASS = 'tsi-gridContainer';
export var LINECHARTCHARTMARGINS = {
    top: 40,
    bottom: 40,
    left: 70,
    right: 60
};
export var LINECHARTXOFFSET = 8;
export var MARKERVALUENUMERICHEIGHT = 20;
export var VALUEBARHEIGHT = 3;
export var SERIESLABELWIDTH = 92;
