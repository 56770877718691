var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component } from "./Component";
import Utils from '../Utils';
var Plot = /** @class */ (function (_super) {
    __extends(Plot, _super);
    function Plot(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.backdropRect = null;
        return _this;
    }
    Plot.prototype.getVisibleSeries = function (aggKey) {
        var _this = this;
        return Object.keys(this.chartComponentData.timeArrays[aggKey]).filter(function (s) {
            return _this.chartComponentData.isSplitByVisible(aggKey, s);
        });
    };
    Plot.prototype.createGradientKey = function (d, splitByIndex, i) {
        return d.aggregateKey.replace(/^[^a-z]+|[^\w:.-]+/gi, "") + '_' + splitByIndex + '_' + i;
    };
    Plot.prototype.addGradientStops = function (d, gradient) {
        var _this = this;
        gradient.selectAll('stop').remove();
        var colorMap = this.chartDataOptions.valueMap;
        if (!d.measures) {
            return;
        }
        //behavior if numeric measures
        var allMeasuresNumeric = Object.keys(d.measures).reduce(function (p, currMeasure) {
            return (typeof d.measures[currMeasure]) === 'number' && p;
        }, true);
        var sumOfMeasures;
        if (allMeasuresNumeric) {
            sumOfMeasures = Object.keys(d.measures).reduce(function (p, currMeasure) {
                return p + d.measures[currMeasure];
            }, 0);
            if (sumOfMeasures <= 0) {
                return;
            }
        }
        var numMeasures = Object.keys(d.measures).length;
        Object.keys(d.measures).reduce(function (p, currMeasure, i) {
            var currFraction = allMeasuresNumeric ? (d.measures[currMeasure] / sumOfMeasures) : (i / numMeasures);
            gradient.append('stop')
                .attr("offset", (p * 100) + "%")
                .attr("stop-color", _this.getColorForValue(currMeasure))
                .attr("stop-opacity", 1);
            var newFraction = allMeasuresNumeric ? (p + currFraction) : ((i + 1) / numMeasures);
            gradient.append('stop')
                .attr("offset", (newFraction * 100) + "%")
                .attr("stop-color", _this.getColorForValue(currMeasure))
                .attr("stop-opacity", 1);
            return newFraction;
        }, 0);
    };
    Plot.prototype.createBackdropRect = function (isVisible) {
        this.backdropRect = this.aggregateGroup.selectAll('.tsi-backdropRect')
            .data([isVisible]);
        this.backdropRect.enter().append('rect')
            .attr('class', 'tsi-backdropRect')
            .attr('x', 0)
            .attr('y', 0)
            .merge(this.backdropRect)
            .attr('visibility', function (d) { return d ? 'visible' : 'hidden'; })
            .attr('width', this.x.range()[1])
            .attr('height', this.height);
        this.backdropRect.exit().remove();
    };
    Plot.prototype.getColorForValue = function (value) {
        return Utils.getColorForValue(this.chartDataOptions, value);
    };
    Plot.prototype.getVisibleMeasures = function (measures) {
        return Object.keys(measures).filter(function (measure) {
            return measures[measure] !== 0;
        });
    };
    Plot.prototype.hasData = function (d) {
        return d.measures && (Object.keys(d.measures).length !== 0);
    };
    return Plot;
}(Component));
export { Plot };
