import ServerClient from "./ServerClient/ServerClient";
import UXClient from "./UXClient/UXClient";
import Utils from "./UXClient/Utils";
var TsiClient = /** @class */ (function () {
    function TsiClient() {
        this.server = new ServerClient();
        this.ux = new UXClient();
        this.utils = Utils;
    }
    return TsiClient;
}());
export default TsiClient;
window.TsiClient = TsiClient;
