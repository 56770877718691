var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChartComponentData } from "./ChartComponentData";
var LineChartData = /** @class */ (function (_super) {
    __extends(LineChartData, _super);
    function LineChartData() {
        var _this = _super.call(this) || this;
        _this.timeMap = {};
        _this._yExtents = [];
        return _this;
    }
    Object.defineProperty(LineChartData.prototype, "yExtents", {
        get: function () {
            return this._yExtents;
        },
        enumerable: false,
        configurable: true
    });
    ;
    LineChartData.prototype.setYExtents = function (idx, value) {
        this._yExtents[idx] = value;
    };
    LineChartData.prototype.resetYExtents = function () {
        this._yExtents = [];
        for (var i = 0; i < this.data.length; i++) {
            this._yExtents.push(null);
        }
    };
    LineChartData.prototype.setTimeMap = function () {
        this.timeMap = this.allValues.reduce(function (timeMap, currVal) {
            var millis = currVal.dateTime.valueOf();
            if (currVal.bucketSize != undefined) {
                millis += (currVal.bucketSize / 2);
            }
            if (currVal.measures != null) {
                if (timeMap[millis] == undefined) {
                    timeMap[millis] = [currVal];
                }
                else {
                    timeMap[millis].push(currVal);
                }
            }
            return timeMap;
        }, {});
    };
    LineChartData.prototype.mergeDataToDisplayStateAndTimeArrays = function (data, aggregateExpressionOptions) {
        if (aggregateExpressionOptions === void 0) { aggregateExpressionOptions = null; }
        _super.prototype.mergeDataToDisplayStateAndTimeArrays.call(this, data, aggregateExpressionOptions);
    };
    return LineChartData;
}(ChartComponentData));
export { LineChartData };
