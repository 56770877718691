import Utils from "../Utils";
import { ChartOptions } from "../Models/ChartOptions";
var Component = /** @class */ (function () {
    function Component(renderTarget) {
        this.TRANSDURATION = (window.navigator.userAgent.indexOf("Edge") > -1) ? 0 : 400;
        this.usesSeconds = false;
        this.usesMillis = false;
        this.chartOptions = new ChartOptions();
        this.teardropD = function (width, height) {
            return "M" + width / 2 + " " + height / 14 + " \n                Q" + width / 1.818 + " " + height / 6.17 + " " + width / 1.2 + " " + height / 2.33 + "\n                A" + width / 2.35 + " " + width / 2.35 + " 0 1 1 " + width / 6 + " " + width / 2.33 + "\n                Q" + width / 2.22 + " " + height / 6.18 + " " + width / 2 + " " + height / 14 + "z";
        };
        this.renderTarget = renderTarget;
    }
    Component.prototype.getString = function (str) {
        return this.chartOptions.stringsInstance.getString(str);
    };
    Component.prototype.themify = function (targetElement, theme) {
        var theme = Utils.getTheme(theme);
        targetElement === null || targetElement === void 0 ? void 0 : targetElement.classed(this.currentTheme, false);
        targetElement === null || targetElement === void 0 ? void 0 : targetElement.classed('tsi-light', false);
        targetElement === null || targetElement === void 0 ? void 0 : targetElement.classed('tsi-dark', false);
        targetElement === null || targetElement === void 0 ? void 0 : targetElement.classed(theme, true);
        this.currentTheme = theme;
    };
    Component.prototype.tooltipFormat = function (d, text, measureFormat, xyrMeasures) {
        if (xyrMeasures === void 0) { xyrMeasures = null; }
    };
    Component.prototype.createTooltipSeriesInfo = function (d, group, cDO) {
        var title = group.append('h2').attr('class', 'tsi-tooltipGroupName tsi-tooltipTitle');
        Utils.appendFormattedElementsFromString(title, d.aggregateName);
        if (d.splitBy && d.splitBy != "") {
            var splitBy = group.append('h4')
                .attr('class', 'tsi-tooltipSeriesName tsi-tooltipSubtitle');
            Utils.appendFormattedElementsFromString(splitBy, d.splitBy);
        }
        if (cDO.variableAlias && cDO.isVariableAliasShownOnTooltip) {
            group.append('h4')
                .text(cDO.variableAlias)
                .attr('class', 'tsi-tooltipVariableAlias tsi-tooltipSubtitle');
        }
    };
    return Component;
}());
export { Component };
