export var KeyCodes;
(function (KeyCodes) {
    KeyCodes[KeyCodes["Tab"] = 9] = "Tab";
    KeyCodes[KeyCodes["Esc"] = 27] = "Esc";
    KeyCodes[KeyCodes["Enter"] = 13] = "Enter";
    KeyCodes[KeyCodes["Up"] = 38] = "Up";
    KeyCodes[KeyCodes["Down"] = 40] = "Down";
})(KeyCodes || (KeyCodes = {}));
// search api params
export var InstancesSort;
(function (InstancesSort) {
    InstancesSort["DisplayName"] = "DisplayName";
    InstancesSort["Rank"] = "Rank";
})(InstancesSort || (InstancesSort = {}));
;
export var HierarchiesExpand;
(function (HierarchiesExpand) {
    HierarchiesExpand["UntilChildren"] = "UntilChildren";
    HierarchiesExpand["OneLevel"] = "OneLevel";
})(HierarchiesExpand || (HierarchiesExpand = {}));
;
export var HierarchiesSort;
(function (HierarchiesSort) {
    HierarchiesSort["Name"] = "Name";
    HierarchiesSort["CumulativeInstanceCount"] = "CumulativeInstanceCount";
})(HierarchiesSort || (HierarchiesSort = {}));
;
export var MetadataPropertyTypes;
(function (MetadataPropertyTypes) {
    MetadataPropertyTypes["Double"] = "Double";
    MetadataPropertyTypes["String"] = "String";
    MetadataPropertyTypes["DateTime"] = "DateTime";
    MetadataPropertyTypes["Long"] = "Long";
})(MetadataPropertyTypes || (MetadataPropertyTypes = {}));
export var ShiftTypes;
(function (ShiftTypes) {
    ShiftTypes["startAt"] = "Start at";
    ShiftTypes["shifted"] = "shifted";
})(ShiftTypes || (ShiftTypes = {}));
export var InterpolationFunctions;
(function (InterpolationFunctions) {
    InterpolationFunctions["None"] = "";
    InterpolationFunctions["CurveLinear"] = "curveLinear";
    InterpolationFunctions["CurveStep"] = "curveStep";
    InterpolationFunctions["CurveStepBefore"] = "curveStepBefore";
    InterpolationFunctions["CurveStepAfter"] = "curveStepAfter";
    InterpolationFunctions["CurveBasis"] = "curveBasis";
    InterpolationFunctions["CurveCardinal"] = "curveCardinal";
    InterpolationFunctions["CurveMonotoneX"] = "curveMonotoneX";
    InterpolationFunctions["CurveCatmullRom"] = "curveCatmullRom";
})(InterpolationFunctions || (InterpolationFunctions = {}));
export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["InvalidInput"] = "InvalidInput";
    ErrorCodes["PartialSuccess"] = "PartialSuccess";
})(ErrorCodes || (ErrorCodes = {}));
// Linechart stack states
export var YAxisStates;
(function (YAxisStates) {
    YAxisStates["Stacked"] = "stacked";
    YAxisStates["Shared"] = "shared";
    YAxisStates["Overlap"] = "overlap";
})(YAxisStates || (YAxisStates = {}));
;
export var DataTypes;
(function (DataTypes) {
    DataTypes["Numeric"] = "numeric";
    DataTypes["Categorical"] = "categorical";
    DataTypes["Events"] = "events";
})(DataTypes || (DataTypes = {}));
;
export var EventElementTypes;
(function (EventElementTypes) {
    EventElementTypes["Diamond"] = "diamond";
    EventElementTypes["Teardrop"] = "teardrop";
})(EventElementTypes || (EventElementTypes = {}));
;
export var TooltipMeasureFormat;
(function (TooltipMeasureFormat) {
    TooltipMeasureFormat["Enveloped"] = "Enveloped";
    TooltipMeasureFormat["SingleValue"] = "SingleValue";
    TooltipMeasureFormat["Scatter"] = "Scatter";
})(TooltipMeasureFormat || (TooltipMeasureFormat = {}));
;
export var valueTypes;
(function (valueTypes) {
    valueTypes["String"] = "String";
    valueTypes["Double"] = "Double";
    valueTypes["Long"] = "Long";
    valueTypes["Dynamic"] = "Dynamic";
    valueTypes["Boolean"] = "Boolean";
    valueTypes["DateTime"] = "DateTime";
})(valueTypes || (valueTypes = {}));
;
