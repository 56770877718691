var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Utils from "../Utils";
import { ChartComponentData } from "./ChartComponentData";
import { GroupedBarChartData } from "./GroupedBarChartData";
var PieChartData = /** @class */ (function (_super) {
    __extends(PieChartData, _super);
    function PieChartData() {
        var _this = _super.call(this) || this;
        _this.visibleValuesSum = 0;
        return _this;
    }
    PieChartData.prototype.mergeDataToDisplayStateAndTimeArrays = function (data, timestamp, aggregateExpressionOptions) {
        if (aggregateExpressionOptions === void 0) { aggregateExpressionOptions = null; }
        ChartComponentData.prototype.mergeDataToDisplayStateAndTimeArrays.call(this, data, aggregateExpressionOptions);
        this.timestamp = Utils.getValueOrDefault({ '': timestamp }, '', this.allTimestampsArray[0]);
        _super.prototype.setValuesAtTimestamp.call(this);
        this.setAllTimestampsArray();
    };
    PieChartData.prototype.updateFlatValueArray = function (timestamp) {
        var _this = this;
        this.visibleValuesSum = 0;
        var values = [];
        Object.keys(this.valuesAtTimestamp).forEach(function (aggKey) {
            Object.keys(_this.valuesAtTimestamp[aggKey].splitBys).forEach(function (splitBy, splitByI) {
                var value = 0;
                if (_this.getSplitByVisible(aggKey, splitBy) && _this.valuesAtTimestamp[aggKey].splitBys[splitBy].measurements != undefined)
                    value = _this.valuesAtTimestamp[aggKey].splitBys[splitBy].measurements[_this.getVisibleMeasure(aggKey, splitBy)];
                values.push({
                    aggKey: aggKey,
                    splitBy: splitBy,
                    val: value,
                    splitByI: splitByI
                });
                _this.visibleValuesSum += Math.abs(value);
            });
        });
        this.flatValueArray = values;
    };
    return PieChartData;
}(GroupedBarChartData));
export { PieChartData };
