var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChartComponentData } from "./ChartComponentData";
import { GroupedBarChartData } from "./GroupedBarChartData";
import * as d3 from "d3";
import Utils from "../Utils";
var ScatterPlotData = /** @class */ (function (_super) {
    __extends(ScatterPlotData, _super);
    function ScatterPlotData() {
        var _this = _super.call(this) || this;
        _this.extents = {};
        _this.extentsSet = false;
        return _this;
    }
    /******** SETS EXTENT OF EACH DATA MEASURE -- MEASURES UPDATED WHEN RENDER CALLED OUTSIDE OF TEMPORAL ********/
    ScatterPlotData.prototype.setExtents = function (measures, forceReset) {
        var _this = this;
        if (forceReset === void 0) { forceReset = false; }
        if (!this.extentsSet || forceReset) {
            // Reset extents
            this.extents = {};
            // Set axis extents
            measures.forEach(function (measure) {
                _this.extents[measure] = d3.extent(_this.allValues, function (v) {
                    if (!v.measures)
                        return null;
                    return measure in v.measures ? v.measures[measure] : null;
                });
            });
            this.extentsSet = true;
        }
    };
    /******** UPDATE EXTENTS BASED ON VISIBLE DATA ********/
    ScatterPlotData.prototype.updateExtents = function (measures) {
        var _this = this;
        var visibleData = [];
        this.data.forEach(function (aggregate) {
            var aggName = Object.keys(aggregate)[0];
            var aggKey = aggregate.aggKey;
            if (_this.displayState[aggKey].visible == true) {
                Object.keys(aggregate[aggName]).forEach(function (splitBy) {
                    if (_this.displayState[aggKey].splitBys[splitBy].visible == true) {
                        visibleData.push(Object.values(aggregate[aggName][splitBy]));
                    }
                });
            }
        });
        visibleData = [].concat.apply([], visibleData);
        measures.forEach(function (measure) {
            _this.extents[measure] = d3.extent(visibleData, function (v) {
                return measure in v ? v[measure] : null;
            });
        });
    };
    /******** UPDATES CHART DATA, ALL TIMESTAMPS, AND VALUES AT THE CURRENT TIMESTAMP ********/
    ScatterPlotData.prototype.mergeDataToDisplayStateAndTimeArrays = function (data, timestamp, aggregateExpressionOptions) {
        if (aggregateExpressionOptions === void 0) { aggregateExpressionOptions = null; }
        ChartComponentData.prototype.mergeDataToDisplayStateAndTimeArrays.call(this, data, aggregateExpressionOptions);
        this.timestamp = (timestamp != undefined && this.allTimestampsArray.indexOf(timestamp) !== -1) ? timestamp : this.allTimestampsArray[0];
        this.setValuesAtTimestamp();
        this.setAllTimestampsArray();
    };
    /******** UPDATES DATA TO BE DRAWN -- IF SCATTER IS TEMPORAL, FLATTENS ALL TIMESTAMP DATA ********/
    ScatterPlotData.prototype.updateTemporalDataArray = function (isTemporal) {
        var _this = this;
        this.temporalDataArray = [];
        if (!isTemporal) {
            this.allTimestampsArray.forEach(function (ts) {
                _this.timestamp = ts;
                _this.setValuesAtTimestamp();
                _this.updateTemporal();
            });
        }
        else {
            this.updateTemporal();
        }
    };
    /******** HELPER TO FETCH DATA AT THE CURRENT TIMESTAMP AND BUILD AN OBJECT FOR THAT TIMESTAMP ********/
    ScatterPlotData.prototype.updateTemporal = function () {
        var _this = this;
        Object.keys(this.valuesAtTimestamp).forEach(function (aggKey) {
            Object.keys(_this.valuesAtTimestamp[aggKey].splitBys).forEach(function (splitBy, splitByI) {
                var measures = null, timestamp = null;
                if (_this.getSplitByVisible(aggKey, splitBy) && _this.valuesAtTimestamp[aggKey].splitBys[splitBy].measurements != undefined) {
                    measures = _this.valuesAtTimestamp[aggKey].splitBys[splitBy].measurements;
                    timestamp = _this.valuesAtTimestamp[aggKey].splitBys[splitBy].timestamp;
                }
                _this.temporalDataArray.push({
                    aggregateKey: aggKey,
                    aggregateKeyI: _this.data.findIndex(function (datum) { return datum.aggKey === aggKey; }),
                    splitBy: splitBy,
                    measures: measures,
                    timestamp: timestamp,
                    splitByI: splitByI
                });
            });
        });
    };
    /******** OVERRIDES GROUPEDBARCHARTDATA -- UPDATES VALUES AT TIMESTAMP WITH MEASURES & TIMESTAMP********/
    ScatterPlotData.prototype.setValuesAtTimestamp = function () {
        var _this = this;
        var aggregateCounterMap = {};
        this.valuesAtTimestamp = {};
        this.data.forEach(function (aggregate, aggI) {
            var aggName = Object.keys(aggregate)[0];
            var aggKey;
            if (aggregateCounterMap[aggName]) {
                aggKey = Utils.createEntityKey(aggName, aggregateCounterMap[aggName]);
                aggregateCounterMap[aggName] += 1;
            }
            else {
                aggKey = Utils.createEntityKey(aggName, 0);
                aggregateCounterMap[aggName] = 1;
            }
            _this.valuesAtTimestamp[aggKey] = {};
            _this.valuesAtTimestamp[aggKey].splitBys = Object.keys(aggregate[aggName])
                .reduce(function (aggSplitBys, splitBy, splitByI) {
                aggSplitBys[splitBy] = {};
                aggSplitBys[splitBy].measurements = aggregate[aggName][splitBy][_this.timestamp];
                aggSplitBys[splitBy].timestamp = _this.timestamp;
                return aggSplitBys;
            }, {});
        });
    };
    return ScatterPlotData;
}(GroupedBarChartData));
export { ScatterPlotData };
