var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as d3 from 'd3';
import { Component } from "./../../Interfaces/Component";
import PlaybackControls from '../PlaybackControls';
import ServerClient from '../../../ServerClient';
import { TsqRange } from '../../Models/TsqRange';
var HistoryPlayback = /** @class */ (function (_super) {
    __extends(HistoryPlayback, _super);
    function HistoryPlayback(renderTarget) {
        var _this = _super.call(this, renderTarget) || this;
        _this.numberOfBuckets = 1000;
        _this.defaultPlaybackRate = 3000; // 3 seconds
        _this.fetchAvailabilityFrequency = 30000; // 30 seconds
        _this.playbackSliderHeight = 88;
        _this.previewApiFlag = '?api-version=2018-11-01-preview';
        _this.serverClient = new ServerClient();
        _this.currentCancelTrigger = null;
        return _this;
    }
    HistoryPlayback.prototype.onGraphicLoaded = function () { };
    HistoryPlayback.prototype.renderBase = function (environmentFqdn, getToken, data, chartOptions) {
        var _this = this;
        this.environmentFqdn = environmentFqdn;
        this.getAuthToken = getToken;
        this.tsqExpressions = data;
        this.chartOptions.setOptions(chartOptions);
        this.playbackRate = this.chartOptions.updateInterval || this.defaultPlaybackRate;
        this.getAuthToken().then(function (authToken) {
            _this.serverClient.getAvailability(authToken, _this.environmentFqdn, _this.previewApiFlag)
                .then(function (availabilityResponse) {
                if (!_this.availabilityInterval) {
                    _this.availabilityInterval = window.setInterval(_this.pollAvailability.bind(_this), _this.fetchAvailabilityFrequency);
                }
                var _a = _this.parseAvailabilityResponse(availabilityResponse), from = _a.from, to = _a.to;
                _this.updateAvailability(from, to);
                _this.targetElement = d3.select(_this.renderTarget);
                _this.targetElement.html('');
                _this.targetElement.classed('tsi-process-graphic-target', true);
                _super.prototype.themify.call(_this, _this.targetElement, _this.chartOptions.theme);
                _this.componentContainer = _this.targetElement
                    .append('div')
                    .classed('tsi-process-graphic-container', true);
                _this.component = _this.componentContainer
                    .append('div')
                    .classed('tsi-process-graphic', true);
                _this.playbackControlsContainer = _this.targetElement
                    .append('div')
                    .classed('tsi-playback-controls-container', true);
                _this.loadResources().then(function () {
                    var initialTimeStamp = _this.chartOptions.initialValue instanceof Date ? _this.chartOptions.initialValue : from;
                    _this.playbackControls = new PlaybackControls(_this.playbackControlsContainer.node(), initialTimeStamp);
                    _this.onSelecTimestamp(initialTimeStamp);
                    _this.draw();
                    if (initialTimeStamp) {
                        _this.playbackControls.play();
                    }
                    window.addEventListener('resize', function () {
                        _this.draw();
                    });
                });
            })
                .catch(function (reason) {
                console.error("Failed while fetching data availability: " + reason);
            });
        })
            .catch(function (reason) {
            console.error("Failed to acquire authentication token: " + reason);
        });
    };
    HistoryPlayback.prototype.pauseAvailabilityUpdates = function () {
        if (this.availabilityInterval) {
            window.clearInterval(this.availabilityInterval);
        }
    };
    HistoryPlayback.prototype.pollAvailability = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getAuthToken().then(function (authToken) {
                        return _this.serverClient.getAvailability(authToken, _this.environmentFqdn, _this.previewApiFlag)
                            .then(function (availabilityResponse) {
                            var _a = _this.parseAvailabilityResponse(availabilityResponse), from = _a.from, to = _a.to;
                            if (from.valueOf() !== _this.availability.fromMillis ||
                                to.valueOf() !== _this.availability.toMillis) {
                                _this.updateAvailability(from, to);
                                _this.playbackControls.render(_this.availability.from, _this.availability.to, _this.onSelecTimestamp.bind(_this), _this.chartOptions, { intervalMillis: _this.playbackRate, stepSizeMillis: _this.availability.bucketSizeMillis });
                                return true;
                            }
                            return false;
                        })
                            .catch(function (reason) {
                            console.error("Failed to update data availability: " + reason);
                            return null;
                        });
                    })];
            });
        });
    };
    HistoryPlayback.prototype.onSelecTimestamp = function (timeStamp) {
        var _this = this;
        var queryWindow = this.calcQueryWindow(timeStamp);
        var tsqArray = this.tsqExpressions.map(function (tsqExpression) {
            tsqExpression.searchSpan = {
                from: queryWindow.fromMillis,
                to: queryWindow.toMillis,
                bucketSize: queryWindow.bucketSize
            };
            return tsqExpression.toTsq();
        });
        this.getAuthToken().then(function (authToken) {
            var _a = _this.serverClient.getCancellableTsqResults(authToken, _this.environmentFqdn, tsqArray), promise = _a[0], cancelTrigger = _a[1];
            // We keep track of the last AJAX call we made to the server, and cancel it if it hasn't finished yet. This is
            // a cheap way to avoid a scenario where we get out-of-order responses back from the server during 'play' mode.
            // We can revisit this at a later time if we need to handle it in a more sophisticated way.
            if (_this.currentCancelTrigger) {
                _this.currentCancelTrigger();
            }
            _this.currentCancelTrigger = cancelTrigger;
            promise.then(function (results) {
                _this.getDataPoints(results);
            });
        });
    };
    HistoryPlayback.prototype.calcQueryWindow = function (timeStamp) {
        var timelineOffset = this.availability.fromMillis;
        var queryToMillis = Math.ceil((timeStamp.valueOf() - timelineOffset) / this.availability.bucketSizeMillis) * this.availability.bucketSizeMillis + timelineOffset;
        return {
            fromMillis: queryToMillis - this.availability.bucketSizeMillis,
            toMillis: queryToMillis,
            bucketSize: this.availability.bucketSizeStr
        };
    };
    HistoryPlayback.prototype.drawBase = function () {
        this.playbackControlsContainer
            .style('width', this.renderTarget.clientWidth + "px")
            .style('height', this.playbackSliderHeight + "px");
        this.playbackControls.render(this.availability.from, this.availability.to, this.onSelecTimestamp.bind(this), this.chartOptions, { intervalMillis: this.playbackRate, stepSizeMillis: this.availability.bucketSizeMillis });
    };
    HistoryPlayback.prototype.updateAvailability = function (from, to) {
        this.availability = new TsqRange(from, to);
        if (this.chartOptions.bucketSizeMillis && this.chartOptions.bucketSizeMillis > 0) {
            this.availability.setNeatBucketSizeByRoughBucketSize(this.chartOptions.bucketSizeMillis);
        }
        else {
            this.availability.setNeatBucketSizeByNumerOfBuckets(this.numberOfBuckets);
        }
        this.availability.alignWithServerEpoch();
    };
    HistoryPlayback.prototype.parseAvailabilityResponse = function (response) {
        var range = response && response.availability && response.availability.range;
        var from = (range && range.from && new Date(range.from)) || null;
        var to = (range && range.to && new Date(range.to)) || null;
        if (from === null || to === null) {
            throw 'Query to get availability returned a response with an unexpected structure';
        }
        return { from: from, to: to };
    };
    return HistoryPlayback;
}(Component));
export default HistoryPlayback;
